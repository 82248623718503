import React, { useState, useEffect } from "react";
import { getToken, getOccupancyData, getListings } from "../utils/api"; // Importing the utility functions
import './dashboard.css';

const BookingTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({});
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  // Fetch saved listings from Firestore when the component mounts
  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array to call it only once on mount

  // Fetch data function
  const fetchData = async () => {
    setLoading(true);
    console.log("Fetching data..."); // Log when data fetch starts
    try {
      // Fetch listings from Firestore
      const savedListings = await getListings();
      if (savedListings.length === 0) {
        throw new Error("No listings found");
      }

      const savedNicknames = {};
      savedListings.forEach((listing) => {
        savedNicknames[listing.id] = listing;
      });

      console.log("Saved listings from Firestore:", savedNicknames); // Log saved listings from Firestore
      const listingIds = Object.keys(savedNicknames);

      const token = await getToken();
      console.log("Token received:", token); // Log received token

      const days = await getOccupancyData(listingIds, token);
      console.log("Days data received:", days); // Log fetched occupancy data

      const listings = {};
      days.forEach((day) => {
        const listingId = day.listingId;
        const date = day.date;
        const status = day.status;

        if (!listings[listingId]) {
          listings[listingId] = {
            listingId,
            nickname: savedNicknames[listingId]?.nickname || listingId,
            bedrooms: savedNicknames[listingId]?.bedrooms || '-',
            location: savedNicknames[listingId]?.location || '-',
            tflZone: savedNicknames[listingId]?.tflZone || '-',
            blNumber: savedNicknames[listingId]?.blNumber || '-', // Add BL Number field
          };
        }

        listings[listingId][date] = status;
      });

      setData(Object.values(listings));
      setError(null);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to get data. Please try again later.");
    } finally {
      setLoading(false);
      console.log("Data fetch complete"); // Log when data fetch completes
    }
  };

  // Filter the data based on selected filters
  const filteredData = data.filter((listing) => {
    const { bedrooms, location, tflZone } = filters;
    return (
      (!bedrooms || listing.bedrooms.toString() === bedrooms) &&
      (!location || listing.location.toLowerCase() === location.toLowerCase()) &&
      (!tflZone || listing.tflZone.toString() === tflZone)
    );
  });

  // Sort the data
  const sortedData = [...filteredData].sort((a, b) => {
    const field = sortField || 'blNumber'; // Default sort by BL Number
    const order = sortOrder === "asc" ? 1 : -1;
    if (a[field] < b[field]) return -1 * order;
    if (a[field] > b[field]) return 1 * order;
    return 0;
  });

  // Generate 31 dates from today
  const allDates = Array.from({ length: 31 }, (_, index) => {
    const date = new Date();
    date.setDate(date.getDate() + index);
    const options = { weekday: 'short', day: '2-digit', month: '2-digit' };
    return {
      original: date.toISOString().split('T')[0],
      formatted: date.toLocaleDateString(undefined, options).replace(/,/g, ""),
    };
  });

// Calculate occupancy percentage for each date
const occupancyData = allDates.map((date) => {
  const totalAvailableListings = filteredData.filter(
    (listing) => listing[date.original] === 'booked' || listing[date.original] === 'available'
  ).length;
  const bookedListings = filteredData.filter((listing) => listing[date.original] === 'booked').length;

  return totalAvailableListings > 0 ? Math.round((bookedListings / totalAvailableListings) * 100) : 0;
});

// Average occupancy calculations
const calculateAverageOccupancy = (startDay, daysRange) => {
  if (daysRange <= 0) return 0;
  const totalOccupancy = occupancyData
    .slice(startDay, startDay + daysRange)
    .reduce((sum, percentage) => sum + percentage, 0);
  return parseFloat((totalOccupancy / daysRange).toFixed(2));
};

const avgNext3Days = calculateAverageOccupancy(0, 3);
const avgNext7Days = calculateAverageOccupancy(0, 7);
const avgFollowing7Days = calculateAverageOccupancy(7, 7);
const avgNext14Days = calculateAverageOccupancy(0, 14);
const avgNext30Days = calculateAverageOccupancy(0, 30);

// New Calculations 1: Current calendar month statistics
const currentMonth = new Date().getMonth();
const currentYear = new Date().getFullYear();
const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

let nightsSold = 0;
let nightsAvailable = 0;
let nightsUnavailable = 0;

filteredData.forEach((listing) => {
  for (let day = 1; day <= daysInCurrentMonth; day++) {
    const date = new Date(currentYear, currentMonth, day).toISOString().split('T')[0];
    if (listing[date] === 'booked') {
      nightsSold++;
    } else if (listing[date] === 'available') {
      nightsAvailable++;
    } else if (listing[date] === 'unavailable') {
      nightsUnavailable++;
    }
  }
});

const totalNightsConsidered = nightsSold + nightsAvailable;
const percentageNightsSold = totalNightsConsidered > 0 ? ((nightsSold / totalNightsConsidered) * 100).toFixed(2) : 0;
const percentageNightsUnsold = totalNightsConsidered > 0 ? ((nightsAvailable / totalNightsConsidered) * 100).toFixed(2) : 0;

// New Calculations 2: Unavailable nights calculations
const totalNightsInMonth = nightsSold + nightsAvailable + nightsUnavailable;
const percentageNightsUnavailable = totalNightsInMonth > 0
  ? ((nightsUnavailable / totalNightsInMonth) * 100).toFixed(2)
  : 0;

// Logging the new calculations
console.log("Nights Sold (Current Month):", nightsSold);
console.log("Percentage Nights Sold (Current Month):", percentageNightsSold + "%");
console.log("Nights Unsold (Current Month):", nightsAvailable);
console.log("Percentage Nights Unsold (Current Month):", percentageNightsUnsold + "%");
console.log("Nights Unavailable (Current Month):", nightsUnavailable);
console.log("Percentage Nights Unavailable (Current Month):", percentageNightsUnavailable + "%");

  // Get unique values for filters
  const uniqueBedrooms = [...new Set(data.map((listing) => listing.bedrooms))].filter((v) => v !== '-');
  const uniqueLocations = [...new Set(data.map((listing) => listing.location))].filter((v) => v !== '-');
  const uniqueTflZones = [...new Set(data.map((listing) => listing.tflZone))].filter((v) => v !== '-');

  // Sorting handler
  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  return (
    <div>
      <h2>Booking Dashboard</h2>
      <div style={{ display: 'flex' }}>
        <div className="statistics">
          <h4>Rolling</h4>
          <div className="statisticsheadlines">
            <h5>3 days</h5>
            <p>{avgNext3Days}%</p>
          </div>
          <div className="statisticsheadlines">
            <h5>7 days</h5>
            <p>{avgNext7Days}%</p>
          </div>
          <div className="statisticsheadlines">
            <h5>Following 7 days</h5>
            <p>{avgFollowing7Days}%</p>
          </div>
          <div className="statisticsheadlines">
            <h5>14 days</h5>
            <p>{avgNext14Days}%</p>
          </div>
            <div className="statisticsheadlines">
            <h5>30 days</h5>
            <p>{avgNext30Days}%</p>
          </div>
        </div>
        <div className="statistics">
          <h4>Month to date</h4>
          <div className="statisticsheadlines">
            <h5>Nights sold</h5>
            <p>{nightsSold}</p>
          </div>
          <div className="statisticsheadlines">
            <h5>Percentage sold</h5>
            <p>{percentageNightsSold}%</p>
          </div>
          <div className="statisticsheadlines">
            <h5>Nights unsold</h5>
            <p>{nightsAvailable}</p>
          </div>
          <div className="statisticsheadlines">
            <h5>Percentage unsold</h5>
            <p>{percentageNightsUnsold}%</p>
          </div>
        </div>
        <div className="statistics">
          <h4>Blocks</h4>
          <div className="statisticsheadlines">
            <h5>Nights blocked</h5>
            <p>{nightsUnavailable}</p>
          </div>
          <div className="statisticsheadlines">
            <h5>Percentage blocked</h5>
            <p>{percentageNightsUnavailable}%</p>
          </div>
        </div>
      </div>
      <div className="filters">
        <label>
          Bedrooms:
          <select
            value={filters.bedrooms || ''}
            onChange={(e) => setFilters({ ...filters, bedrooms: e.target.value })}
          >
            <option value="">All</option>
            {uniqueBedrooms.map((bedroom) => (
              <option key={bedroom} value={bedroom}>
                {bedroom}
              </option>
            ))}
          </select>
        </label>
        <label>
          Location:
          <select
            value={filters.location || ''}
            onChange={(e) => setFilters({ ...filters, location: e.target.value })}
          >
            <option value="">All</option>
            {uniqueLocations.map((location) => (
              <option key={location} value={location}>
                {location}
              </option>
            ))}
          </select>
        </label>
        <label>
          TfL Zone:
          <select
            value={filters.tflZone || ''}
            onChange={(e) => setFilters({ ...filters, tflZone: e.target.value })}
          >
            <option value="">All</option>
            {uniqueTflZones.map((zone) => (
              <option key={zone} value={zone}>
                {zone}
              </option>
            ))}
          </select>
        </label>
      </div>
      <button onClick={fetchData} disabled={loading} style={{ marginBottom: '20px' }}>
        {loading ? "Loading..." : "Refresh Data"}
      </button>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <table className="compact-table" border="1">
        <thead>
          <tr>
            <th onClick={() => handleSort('blNumber')}>BL Number</th>
            <th onClick={() => handleSort('nickname')}>Nickname</th>
            <th style={{ display: 'none' }}>Listing ID</th>
            <th onClick={() => handleSort('bedrooms')}>Bedrooms</th>
            <th onClick={() => handleSort('location')}>Location</th>
            <th onClick={() => handleSort('tflZone')}>TfL Zone</th>
            {allDates.map((date) => (
              <th key={date.original}>{date.formatted}</th>
            ))}
          </tr>
          <tr className="occupancy-row">
            <th colSpan="5" style={{ fontWeight: 'bold' }}>Occupancy %</th>
            {occupancyData.map((percentage, index) => (
              <th key={allDates[index].original} style={{ textAlign: 'center', fontWeight: 'bold' }}>
                {percentage}%
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((listing) => (
            <tr key={listing.listingId}>
              <td>{listing.blNumber}</td> {/* Add BL Number */}
              <td>{listing.nickname}</td>
              <td style={{ display: 'none' }}>{listing.listingId}</td>
              <td>{listing.bedrooms}</td>
              <td>{listing.location}</td>
              <td>{listing.tflZone}</td>
              {allDates.map((date) => {
                const status = listing[date.original];
                let className = '';

                if (status === "booked") {
                  className = 'booked';
                } else if (status === "unavailable") {
                  className = 'unavailable';
                } else {
                  className = 'available';
                }

                return <td key={date.original} className={className}></td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BookingTable;
