// src/utils/api.js

import axios from "axios";

// Utility to get the token from the backend
let cachedToken = null;
let cachedTokenExpiry = null;

export const getToken = async () => {
  if (cachedToken && cachedTokenExpiry && Date.now() < cachedTokenExpiry) {
    return cachedToken; // Use cached token if it's still valid
  }

  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || window.location.origin}/get-token`);
    cachedToken = response.data.access_token;
    cachedTokenExpiry = Date.now() + (3600 * 1000); // Assuming the token is valid for 1 hour
    return cachedToken;
  } catch (error) {
    console.error("Error fetching token:", error);
    throw new Error("Failed to get token");
  }
};

// Utility to fetch occupancy data from Guesty API
export const getOccupancyData = async (listingIds, token) => {
  try {
    const today = new Date().toISOString().split('T')[0];
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 31);
    const end = endDate.toISOString().split('T')[0];

    const response = await axios.get(
      `https://open-api.guesty.com/v1/availability-pricing/api/calendar/listings?listingIds=${listingIds.join(",")}&startDate=${today}&endDate=${end}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.data.days;
  } catch (error) {
    console.error("Error fetching occupancy data:", error);

    if (error.response && error.response.status === 429) {
      console.error("Rate limit exceeded:", error.response.headers);
      alert(`Rate limit exceeded. Details:\n${JSON.stringify(error.response.headers, null, 2)}`);
    }

    throw new Error("Failed to get occupancy data");
  }
};

// Utility to get all listings from the backend
export const getListings = async () => {
  try {
    console.log('Fetching listings from backend...');
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL || window.location.origin}/listings`);
    console.log('Fetched listings response:', response);
    return response.data;
  } catch (error) {
    console.error("Error fetching listings:", error);
    throw new Error("Failed to get data. Please try again later");
  }
};

// Utility to save a listing to the backend
export const saveListing = async (listing) => {
  if (!listing.id) {
    console.error('Listing ID is missing, cannot save to Firestore:', listing);
    throw new Error('Listing ID is missing');
  }

  try {
    await axios.post(`${process.env.REACT_APP_BACKEND_URL || window.location.origin}/listings`, listing);
  } catch (error) {
    console.error("Error saving listing:", error);
    throw new Error("Failed to save listing");
  }
};

// Utility to delete a listing from the backend
export const deleteListing = async (id) => {
  try {
    await axios.delete(`${process.env.REACT_APP_BACKEND_URL || window.location.origin}/listings/${id}`);
  } catch (error) {
    console.error("Error deleting listing:", error);
    throw new Error("Failed to delete listing");
  }
};
