import React, { useState, useEffect } from 'react';
import CSVReader from 'react-csv-reader';
import './settings.css';
import { getListings, saveListing, deleteListing } from '../utils/api'; // Import your API functions

const SettingsPage = () => {
  const [listings, setListings] = useState([]);

  // Fetch listings from Firestore on component mount and after updates
  const fetchListings = async () => {
    try {
      const listingsFromDB = await getListings(); // Fetch listings from Firestore
      console.log('Fetched listings:', listingsFromDB); // Log the fetched data for debugging
      setListings(listingsFromDB);
    } catch (error) {
      console.error('Error fetching listings:', error);
    }
  };

  useEffect(() => {
    fetchListings();
  }, []);

  // Handle changes to listing input fields
  const handleInputChange = (index, field, value) => {
    const updatedListings = [...listings];
    updatedListings[index][field] = value;
    setListings(updatedListings);
  };

  // Save all listings to Firestore
  const saveListings = async () => {
    try {
      for (const listing of listings) {
        if (!listing.id) {
          listing.id = generateUniqueId(); // Ensure all listings have an id
        }
        await saveListing(listing); // Save each listing to Firestore
      }
      alert('Listings saved successfully!');
      await fetchListings(); // Refresh listings after save
    } catch (error) {
      console.error('Error saving listings:', error);
    }
  };

  // Delete a listing from Firestore
  const handleDelete = async (index) => {
    try {
      const listingId = listings[index].id;
      await deleteListing(listingId); // Delete from Firestore
      await fetchListings(); // Refresh listings after delete
    } catch (error) {
      console.error('Error deleting listing:', error);
    }
  };

  // Handle CSV import and update listings state
  const handleCSVImport = (data) => {
    const newImportedListings = data.map((row) => ({
      id: row[0] || generateUniqueId(), // Use `id` instead of `listingId`
      nickname: row[1],
      bedrooms: row[2],
      location: row[3],
      tflZone: row[4],
      blNumber: row[5],
    }));

    const updatedListings = [...listings];
    newImportedListings.forEach((newListing) => {
      const existingIndex = updatedListings.findIndex(
        (listing) => listing.id === newListing.id
      );
      if (existingIndex !== -1) {
        updatedListings[existingIndex] = newListing;
      } else {
        updatedListings.push(newListing);
      }
    });

    setListings(updatedListings);
  };

  // Function to generate a unique ID for listings
  const generateUniqueId = () => {
    return `listing-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  };

  return (
    <div className="settings-page">
      <h2>Settings</h2>
      <CSVReader onFileLoaded={handleCSVImport} />
      <table className="listings-table">
        <thead>
          <tr>
            <th>Listing ID</th>
            <th>Nickname</th>
            <th>Bedrooms</th>
            <th>Location</th>
            <th>TfL Zone</th>
            <th>BL Number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {listings.map((listing, index) => (
            <tr key={listing.id}>
              <td>{listing.id}</td>
              <td>
                <input
                  type="text"
                  value={listing.nickname}
                  onChange={(e) => handleInputChange(index, 'nickname', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={listing.bedrooms}
                  onChange={(e) => handleInputChange(index, 'bedrooms', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={listing.location}
                  onChange={(e) => handleInputChange(index, 'location', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={listing.tflZone}
                  onChange={(e) => handleInputChange(index, 'tflZone', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={listing.blNumber}
                  onChange={(e) => handleInputChange(index, 'blNumber', e.target.value)}
                />
              </td>
              <td>
                <button onClick={() => handleDelete(index)} className="delete-button">
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={saveListings} className="save-button">
        Save All Changes
      </button>
    </div>
  );
};

export default SettingsPage;