import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import BookingTable from './components/BookingTable';
import SettingsPage from './components/SettingsPage';

const App = () => {
  return (
    <Router>
      <nav>
        <ul>
          <li><Link to="/">Occupancy Dashboard</Link></li>
          <li><Link to="/settings">Settings</Link></li>
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<BookingTable />} />
        <Route path="/settings" element={<SettingsPage />} />
      </Routes>
    </Router>
  );
};

export default App;